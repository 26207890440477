<template>
  <main>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-9 col-xl-8">
          <ThisComponent></ThisComponent>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Vue from "vue";
import ThisComponent from "base/components/Order";

ThisComponent.mounted = function () {
  this.$store.commit("app/updatePageClass", "page--order");

  this.afterMounted();
};

ThisComponent.methods.beforeMake = function (cart) {
  Vue.$logger.debug("Order | Before make order from Component");
  return new Promise((resolve, reject) => {
    resolve();
  });
};

ThisComponent.methods.afterMake = function () {
  this.$store.dispatch("user/getBalance");

  this.$store.dispatch("tasks/get").then(() => {
    const activeTasks = this.$store.getters["tasks/active"];

    if (
      !!activeTasks.length &&
      activeTasks.some((task) =>
        ["act_request", "approvement_request"].includes(task.type),
      )
    ) {
      this.preventComponent = true;
    }
  });

  Vue.$logger.debug("Order | After make from Component");
};

export default {
  components: {
    ThisComponent,
  },
};
</script>
